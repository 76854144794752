import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import About from "../components/about"

const SecondPage = () => (
  <Layout>
    <SEO title="About me" />
    <div className="content">
      <About />
    </div>
  </Layout>
)

export default SecondPage
