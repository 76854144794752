import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Image from "./image-facebook"

export default function About() {
  return (
    <div className="mainWrapper-about">
      <Image />
      <h1>
        Hey there fellas!
        <span className="emoji" role="img" aria-label="hand" aria-hidden="true">
          👋
        </span>
      </h1>
      <article>
        <p className="about">
          <span className="about-me">
            Hi! just call me Marsh. I'm a front-end developer who loves to code
            and discover things.
          </span>
          <br />I enjoy creating and crafting new ideas. I love learning new
          technologies and giving my 110%. Coding and building awesome things is
          my passion.
        </p>
      </article>

      <p className="contact-me">
        <span className="emoji" role="img" aria-label="mail" aria-hidden="true">
          📮
        </span>
        You can contact me at
        <i>
          <u> mn.marcialnorte@gmail.com</u>
        </i>
      </p>
      <br />
      <AniLink to="/" cover bg="#663399" className="back">
        <span>Go Back</span>
      </AniLink>
    </div>
  )
}
